import React from "react";

import styles from "./impressum.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faMapMarkerAlt,
  faPhoneAlt,
  faMobileAlt,
  faAddressCard,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { usePathname } from "next/navigation";
import { Routes } from "../../helperFunctions/routes";

function Impressum() {
  const RenderHeadline = () => {
    const pathname = usePathname();
    return pathname === Routes.Impressum ? (
      <h1 className={styles["header"]}>Impressum</h1>
    ) : (
      <p className={styles["header"]}>Impressum</p>
    );
  };

  return (
    <div
      className={`component-layout component-layout-border ${styles["impressum-container"]}`}
    >
      {RenderHeadline()}
      <div className={styles["wrapper"]}>
        <div className={styles["left-side"]}>
          <p className={styles["company-name"]}>Musliu Reinigung</p>
          <div>
            <FontAwesomeIcon title="Inhaber" icon={faUser} />
            <span>Valon Musliu</span>
          </div>
          <div>
            <FontAwesomeIcon title="Adresse" icon={faMapMarkerAlt} />
            <span>Ameisenkamp 19, 22523 Hamburg</span>
          </div>
          <div>
            <FontAwesomeIcon title="Telefon" icon={faPhoneAlt} />
            <span>(040) 51907299</span>
          </div>
          <div>
            <FontAwesomeIcon title="Mobil" icon={faMobileAlt} />
            <span>(0176) 48833463</span>
          </div>
          <div>
            <FontAwesomeIcon title="USt-IdNr." icon={faAddressCard} />
            <span>DE 349346316</span>
          </div>
          <div>
            <FontAwesomeIcon title="Email" icon={faEnvelope} />
            <a
              href="mailto:musliu-reinigung@outlook.de"
              className="text"
              title="musliu-reinigung@outlook.de"
            >
              musliu-reinigung@outlook.de
            </a>
          </div>
        </div>
        <div className={styles["right-side"]}>
          <div className={styles["social-media"]}>
            <a
              href="https://www.instagram.com/musliureinigung.hamburg/"
              target="_blank"
              rel="noopener noreferrer"
              title="Instagram"
            >
              <FontAwesomeIcon
                title="Instagram"
                icon={faInstagram}
                style={{ fontSize: 50, color: "#125688" }}
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61556650270845"
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
            >
              <FontAwesomeIcon
                title="Facebook"
                icon={faFacebook}
                style={{ fontSize: 50, width: 50, color: "#3B5998" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Impressum;
